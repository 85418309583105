<template>
  <el-dialog
    id="advertise-richTextPreview"
    title="富文本预览"
    :visible.sync="show"
    top="10vh"
  >
    <iframe :src="iframe" frameborder="0"></iframe>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      iframe: "",
    };
  },
};
</script>

<style lang="scss">
#advertise-richTextPreview {
  & > .el-dialog {
    width: 375px !important;
    height: 80vh;
    display: flex;
    flex-direction: column;
    .el-dialog__body {
      flex-grow: 1;
      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
